@import "~antd/dist/antd.less";

body {
  font: 18px "Gilroy", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f2f5;
}

@primary-color: #00a884; // primary color for all components
@link-color: #00a884; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 15px; // major text font size
@heading-color: rgba(0, 0, 0, 0.85); // heading text color
@text-color: rgba(0, 0, 0, 0.65); // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 2px; // major border radius
@border-color-base: #d9d9d9; // major border color
@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers

a {
  color: @link-color;
}

// write few tailwind flex properties
.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.item-center {
  align-items: center;
}

.item-start {
  align-items: flex-start;
}

.item-end {
  align-items: flex-end;
}

.item-between {
  align-items: space-between;
}

.item-around {
  align-items: space-around;
}

.h-screen {
  height: 100vh;
}

.ant-form-item {
  margin-top: 3rem;
}

.google-login {
  border: none;
  border-radius: 8px !important;
}

.inner-layout {
  padding: 20px;
}
